import axios from 'axios'
import { objectToQuery } from '@/auth/utils'
import jwtDefaultConfig from '../auth/jwt/jwtDefaultConfig'

const appLogin = payload => new Promise((resolve, reject) => {
  axios
    .post(`${jwtDefaultConfig.groupsApi}/api/Account/LoginAsync`, payload)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appUnionList = payload => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/Union/ListAsync`, payload)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appDashboardCounts = () => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/Admin/GetGroupsCount`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appGetAddress = payload => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/Address/GetAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appGetStates = () => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/State/ListAsync`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appGetDistricts = payload => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/District/ListAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appGetSubDistrict = payload => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/SubDistrict/ListAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appGetBlocks = payload => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/Block/ListAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appGetPanchayat = payload => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/Panchayat/ListAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appTransactionTypes = () => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/TransactionType/ListAsync`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appGetUnionById = payload => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/Union/GetAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appGetGroupSetting = payload => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/UnionValidation/GetAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appGetUserById = payload => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/User/GetAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appEventTypes = () => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/EventType/ListAsync`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appEventList = payload => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/Event/ListAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appEventById = payload => new Promise((resolve, reject) => {
  axios
    .post(`${jwtDefaultConfig.groupsApi}/api/Event/GetAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appUpdateEvent = payload => new Promise((resolve, reject) => {
  axios
    .put(`${jwtDefaultConfig.groupsApi}/api/Event/UpsertAsync`, payload)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appUpdateUnion = payload => new Promise((resolve, reject) => {
  axios
    .put(`${jwtDefaultConfig.groupsApi}/api/Union/UpsertAsync`, payload)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appSetGroupSetting = payload => new Promise((resolve, reject) => {
  axios
    .put(`${jwtDefaultConfig.groupsApi}/api/UnionValidation/UpsertAsync`, payload)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appUpdateUsers = payload => new Promise((resolve, reject) => {
  axios
    .put(`${jwtDefaultConfig.groupsApi}/api/User/UpsertAsync`, payload)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appExportUserExcel = payload => new Promise((resolve, reject) => {
  axios
    .post(`${jwtDefaultConfig.groupsApi}/api/Import/ExportUserExcel`, payload, {
      responseType: 'blob',
    })
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appBankById = payload => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/Bank/GetAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appUpdateBank = payload => new Promise((resolve, reject) => {
  axios
    .put(`${jwtDefaultConfig.groupsApi}/api/Bank/UpsertAsync`, payload)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appCopyUnion = payload => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/Import/CopyUnionData?key=yes_migrate&${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appDeleteUnion = payload => new Promise((resolve, reject) => {
  axios
    .delete(`${jwtDefaultConfig.groupsApi}/api/Union/DeleteAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appDeleteMember = payload => new Promise((resolve, reject) => {
  axios
    .delete(`${jwtDefaultConfig.groupsApi}/api/User/DeleteAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appDeleteBank = payload => new Promise((resolve, reject) => {
  axios
    .delete(`${jwtDefaultConfig.groupsApi}/api/Bank/DeleteAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appDeleteEvents = payload => new Promise((resolve, reject) => {
  axios
    .delete(`${jwtDefaultConfig.groupsApi}/api/Event/DeleteAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appUploadUsers = payload => new Promise((resolve, reject) => {
  axios.post(`${jwtDefaultConfig.groupsApi}/api/Import/UploadUsers`, payload)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appUploadUsersAndvalidate = payload => new Promise((resolve, reject) => {
  axios.post(`${jwtDefaultConfig.groupsApi}/api/Import/ValidateUploadedExcel`, payload, {
    responseType: 'blob',
  })
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appUpsertImportedUser = payload => new Promise((resolve, reject) => {
  axios
    .post(`${jwtDefaultConfig.groupsApi}/api/User/UpsertImportedUserAsync`, payload)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appDeleteTransaction = payload => new Promise((resolve, reject) => {
  axios
    .delete(`${jwtDefaultConfig.groupsApi}/api/UserTransactions/DeleteAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appDeleteTransactionPermanent = payload => new Promise((resolve, reject) => {
  axios
    .delete(`${jwtDefaultConfig.groupsApi}/api/UserTransactions/DeletePermanentAsync?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appGetLogs = payload => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/Activity/GetLogs?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

const appGetUnionMessageCount = payload => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.groupsApi}/api/Union/GetUnionMessageCount?${objectToQuery(payload)}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

export {
  appLogin,
  appUnionList,
  appDashboardCounts,
  appGetAddress,
  appGetStates,
  appGetDistricts,
  appGetSubDistrict,
  appGetBlocks,
  appGetPanchayat,
  appTransactionTypes,
  appGetUnionById,
  appGetGroupSetting,
  appGetUserById,
  appEventTypes,
  appEventList,
  appUpdateUnion,
  appSetGroupSetting,
  appUpdateUsers,
  appUpdateEvent,
  appEventById,
  appBankById,
  appUpdateBank,
  appDeleteUnion,
  appDeleteBank,
  appDeleteEvents,
  appDeleteMember,
  appUploadUsers,
  appUpsertImportedUser,
  appExportUserExcel,
  appUploadUsersAndvalidate,
  appCopyUnion,
  appDeleteTransaction,
  appGetLogs,
  appDeleteTransactionPermanent,
  appGetUnionMessageCount,
}
